.stepBoxWrapper{
  h4{
    margin-bottom: 8px;
  }
}
.list {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-auto-flow: dense;
  gap: 12px;
}
