.list {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-auto-flow: dense;
  gap: 12px;
  overflow: auto;
  max-height: 460px;
}

.cardStyle {
  overflow: auto;
}

.stepBoxWrapper {
  h5 {
    margin-bottom: 16px;

  }

  .driversWrapper {
    margin: 32px 0;
  }
}