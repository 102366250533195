// start expertCard


.expertCardWrapper {
  position: relative;
  height: calc(100% - 44px - 1.5rem);

  .rating {
    position: absolute;
    top: 0;
    right: 0;
  }

  .imgWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .avatarImg {
      width: 120px;
      height: 120px;
    }

    .imgCaption {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      color: #AE9CFC;
      background-color: #2D293E;
      font-size: 12px;
      border-radius: 8px;
      line-height: 16.8px;
      padding: 2px 8px;
      min-width: 100px;
      white-space: nowrap;
      text-align: center;
    }
  }

  .primaryInfo {
    text-align: center;
    margin: 12px 0;
    
    .addressInfo{
      margin-top: 6px;
    }

    h5 {
      color: #f8f7fa;
    }

    p {
      padding-top: 6px;
     
    }
    span{
      margin-left: 8px;
      color: #E0DFE3;
    }
  }

  .overview {
    text-align: center;

    p {
      padding: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
}

// end expertCard