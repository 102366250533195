.grid {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;

  &+.grid {
    margin-top: 1.25rem;
  }
  
  &[data-colsize="3"] {
    grid-template-columns: 1fr 1fr 1fr;
  }
  &[data-colsize="2.1"] {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  }
  &[data-colsize="2"] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
  &[data-colsize="1"] {
    grid-template-columns: minmax(0, 1fr);
  }
  &[data-colsize="1.3"] {
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
  }
  &.helpcenterGrid {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2.3fr);
  }
  &.digiDoubleGrid {
    grid-template-columns: minmax(0, 2.2fr) minmax(0, 1fr);
  }
  &.paymentsGrid {
    grid-template-columns: minmax(0, 2.25fr) minmax(0, 1fr);
  }
  &.twoColUneven {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  }
  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr !important;
  }
}