.sortingSelect {
  text-align: left;
}

.divider {
  border-bottom: 1px solid #2c2b33;
  margin: 1rem -1.5rem;
}
.filterCard {
  height: auto;
}

.accordionWrapper {
}

.darkAccordion {
  background-color: transparent !important;
  box-shadow: none !important;

  .expandMore {
    color: #adaab5;
  }
  .accordionHeader {
    padding: 0;
    margin-bottom: 0;
    color: #f8f7fa;
    margin: 16px 0 8px;
  }
  .accordionBody {
    padding: 0px 0px 8px 0px;

    .checkBoxWrapper {
      padding: 9.3px 0;
    }
    .checkBox {
      color: #e0dfe3;
      padding-top: 2px;
      margin-bottom: 0;
    }
  }
}
