.contentBox {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 25vh;

  p {
    margin-bottom: 10px;
    font-size: 16px;
    margin: 12px 0 32px 0;
  }
  .addProgramBtn {
    svg {
      path {
        fill: currentColor;
      }
    }
  }
}
