.customContainer {
  grid-template-columns: minmax(0, 320px) minmax(0, 3fr);
}
.buttonWrapper{
  margin: 20px 0 32px 0;
}

.noSmeBox {
  max-width: 700px;
  margin: 2rem auto;
}

.loadingWrapper {
  position: relative;
  min-height: 45vh;
  border-radius: 20px;
  overflow: hidden;
}