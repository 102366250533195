@import "../../../styles/variables";

.checkBox {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  $size: 1.715em;

  .inputWrapper {
    min-width: $size;
    min-height: $size;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: $size;
      width: $size;
      background-color: #302e3a;
      border-radius: 6px;

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 3px;
        width: 7px;
        height: 14px;
        border: solid #fdfcff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    [type="radio"] + .checkmark {
      border-radius: 20px;

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 7px;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: #fdfcff;
      }
    }

    &:hover input ~ .checkmark {
      background-color: #49465a;
    }
    input:checked ~ .checkmark {
      background: $primary-gradient;
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
  }

  .text {
    display: block;
    margin-top: 2px;
    margin-left: 12px;
    color: #e0dfe3;
  }
}
