.status {
  display: flex;
  width: 170px;
  height: 24px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.pending {
  background: rgba(253, 252, 255, 0.05);
  color: #adaab5;
}

.inProgress {
  background-color: rgba(200, 185, 111, 0.08);
  color: #C8B96F;
}

.approved {
  background: rgba(46, 160, 130, 0.08);
  color: #2ea082;
}

.error {
  background: rgba(224, 75, 70, 0.15);
  color: #d86561;
}
