.dropdown-menu {
  background-color: #2c2b33;
  border: 0.75px solid rgba(255, 255, 255, 0.08);
  border-radius: 22px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  padding: 0;

  li {
    margin: 0 !important;
  }

  a {
    color: #e0dfe3;
    padding: 1rem;
    border-radius: 22px;

    &:hover {
      background-color: #151419;
      color: #e0dfe3;
    }
  }
}

.alert {
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  color: #f3f2f7;
  border: none;

  .alert-link {
    color: #8977d8;
    font-weight: 500;
  }

  &.alert-primary {
    background-color: #1f1e25;
  }
}

.MuiCollapse-root {
  min-height: auto !important;
}

.MuiDialog-container {

  .input-field,
  .select {
    background-color: #2c2b33;
  }

  .MuiDialog-paper {
    border: 0.75px solid rgba(255, 255, 255, 0.08);
  }

  * {
    font-family: "Inter", sans-serif !important;
  }

  #dialog-title {
    >div {
      flex-grow: 1;
    }

    font-size: 1.25rem;
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  [data-testid="CloseIcon"] {
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
}

.MuiPaper-root.MuiAlert-filledSuccess {
  background-color: rgba(46, 160, 130, 0.08);
  color: #2ea082;
}

.MuiPaper-root.MuiAlert-filledError {
  background-color: rgba(224, 75, 70, 0.15);
  color: #d86561;
}

body .MuiTooltip-tooltip {
  font-family: "Inter", sans-serif;
  background-color: #e0dfe3;
  font-size: 14px;
  color: #151419;
  line-height: 1.4;
  font-weight: 400;
  padding: 1rem;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  position: relative;

  &:before {
    position: absolute;
    left: 48%;
    content: "";
    top: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #e0dfe3;
  }
}

body [data-popper-placement=top] .MuiTooltip-tooltip{
  &::before {
    top: 100%;
    transform: rotate(180deg);
  }
}

body .MuiSlider-root {
  margin-bottom: 10px;
  height: 6px;

  .MuiSlider-rail {
    background-color: #2c2b33;
    border-radius: 0.5rem;
  }

  .MuiSlider-track,
  .MuiSlider-thumb,
  .MuiSlider-valueLabel {
    background: linear-gradient(273deg, #c068cf 0%, #7e7ad9 100%);
    border: none;
    background: linear-gradient(273deg, #c068cf 0%, #7e7ad9 100%);
  }

  .MuiSlider-valueLabel:before {
    background: linear-gradient(273deg, #c068cf 0%, #7e7ad9 100%);
  }

  .MuiSlider-markLabel {
    font-size: 12px;
    color: #adaab5;
  }

  .MuiSlider-mark {
    background-color: transparent;
  }
}

body .MuiInputBase-root.MuiOutlinedInput-root {
  font-family: "Inter", sans-serif;
  color: #e0dfe3;
  border: none;
  outline: none;

  .MuiSelect-select {
    padding: 0 10px 0 0;
    z-index: 1;
  }

  svg {
    width: 30px;
    margin-left: -10px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

.tableWrapper {
  .MuiPaper-root {
    border-radius: 0;
    background-color: #1f1e25;
    box-shadow: none;
  }

  .MuiTableCell-root {
    color: #e0dfe3;
    font-family: "Inter", sans-serif;
    border-bottom: 0.5px solid rgba(253, 252, 255, 0.08);
    font-size: 1rem;
    font-weight: 400;
    padding: 1.5rem;

    a {
      color: inherit;
    }
  }

  .MuiTableHead-root .MuiTableCell-root {
    font-size: 14px;
    border-bottom: 0.75px solid rgba(255, 255, 255, 0.12);
    color: #adaab5;
  }

  .MuiTableBody-root {
    th {
      font-weight: 500;
    }
  }
}

body .MuiMenu-paper {
  border-radius: 1.25rem;
  border: 0.75px solid rgba(255, 255, 255, 0.08);
  background: rgba(44, 43, 51, 0.7);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(16px);

  .MuiMenu-list {
    color: #e0dfe3;
    padding: 0;

    li {
      font-size: 14px;
      font-family: "Inter", sans-serif;
      font-weight: 400;
      padding: 0.75rem 1rem;
      &.MuiMenuItem-divider {
        border-bottom: 0.75px solid rgba(255, 255, 255, 0.08);
      }
      &.Mui-selected,
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}

body .MuiPopover-paper {
  border-radius: 1rem;
  background-color: #2c2b33;
}

body .MuiAutocomplete-root {
  width: 100%;

  .MuiAutocomplete-popupIndicator svg path,
  .MuiAutocomplete-clearIndicator svg path {
    fill: #fff;
  }
}

body {
  .MuiToggleButtonGroup-root.customTabs {
    background-color: #2c2b33;
    border-radius: 100rem;
    padding: 0.25rem;
    display: flex;
    text-align: center;
    gap: 2px;
    justify-content: space-between;
    margin: 1.25rem 0 0.75rem;

    button {
      font-size: 14px;
      color: #adaab5;
      flex-grow: 1;
      padding: 0.4rem 2rem;
      border-radius: 100rem !important;
      text-transform: none;
      border: none;
      margin: 0;
      cursor: pointer;

      &.Mui-selected,
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
        color: #fdfcff;
      }
    }
  }
}

body {
  .MuiTabs-root.customTab {
    .MuiTabs-indicator {
      background-color: #8977D8;
    }
  }
}

// Mui Switch
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #fdfcff !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background: linear-gradient(273deg, #c068cf 0%, #7e7ad9 100%) !important;
}

.table-dark {
  --bs-table-bg: #1f1e25;
}

.MuiListItemButton-root.checkBoxCard {
  border: 1px #302E3A solid;
  border-radius: 12px;
  padding: 0;

  .content {
    padding: 1rem;
    flex: 1;
    width: 100%;
  }
}

.text-danger {
  --bs-danger-rgb: 216, 101, 97;
}