@import "../../../styles/variables";

.button {
  display: inline-flex;
  height: 44px;
  line-height: 44px;
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: $font-medium;
  font-size: $primary-size;
  color: $heading-color;
  text-decoration: none;
  border-radius: 500px;
  -webkit-appearance: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  cursor: pointer;
  border: none;
  &:global(.btn-sm) {
    font-size: 14px;
    border: none !important;
    font-weight: 400;
    padding: 0 8px;
    height: 24px;
    line-height: 24px;
  }
  &.primary {
    background-image: $primary-gradient;
  }
  &.primaryDisabled {
    background-image: $primary-gradient;
    opacity: 0.5;
    cursor: no-drop;
  }
  &.light {
    background-color: #fdfcff;
    color: #151419;
    border: 1px solid transparent;
    &:hover {
      background-color: #151419;
      color: #fdfcff;
      border: 1px solid #302e3a;
      svg path {
        fill: #fdfcff;
      }
    }
  }
  &.lightDisabled {
    background-color: #fdfcff;
    color: #151419;
    opacity: 0.5;
    cursor: no-drop;
  }
  &.noBorder {
    color: #fdfcff;
    background: transparent;
    border: none;
  }
  &.dark {
    background-color: $grey-bg;
    border: 1.5px solid #5a576a;
    &:hover {
      background-color: #151419;
      color: #fdfcff;
      svg path {
        fill: #fdfcff;
      }
    }
  }
  &.darkDisabled {
    cursor: no-drop;
    opacity: 0.5;
    background-color: $grey-bg;
    border: 1.5px solid #5a576a;
    &:hover {
      background-color: #151419;
      color: #fdfcff;
      svg path {
        fill: #fdfcff;
      }
    }
    &:active {
      pointer-events: none;
    }
  }
  &.grey {
    background-color: #2c2b33;
    border: 1.5px solid #5a576a;
    &:hover {
      background-color: #151419;
      color: #fdfcff;
      svg path {
        fill: #fdfcff;
      }
    }
  }
  &.transparent {
    background-color: transparent;
    font-weight: 400;
    border: 1.5px solid #302e3a;
    &:hover {
      background-color: #151419;
      color: #fdfcff;
      svg path {
        fill: #fdfcff;
      }
    }
  }
  &.disabled {
    background-color: $grey-bg;
    border: 1.5px solid #5a576a;
    opacity: 0.9;
    color: #5a576a;
    cursor: no-drop;
    pointer-events: none;
  }
  &.searchIcon {
    border-radius: 50%;
    background-color: $grey-bg;
    padding: 0 12px;
  }
  &.fullwidth {
    width: 100%;
    background-color: #fdfcff;
    color: #151419;
    margin: 0 15px 24px 15px;
  }
  &.blue {
    background-color: #4a8cff;
    font-weight: 400;
    &:hover {
      background-color: #1a66e9;
    }
  }
  &.greyNoBorder {
    background-color: #2c2b33;
    &:hover {
      background-color: #151419;
      color: #fdfcff;
      svg path {
        fill: #fdfcff;
      }
    }
  }
  &.success {
    background-color: #2ea082;
    font-weight: 400;
    color: #151419;
  }
  &.error {
    background-color: rgba(224, 75, 70, 0.15);
    font-weight: 400;
    color: #d86561;
  }
  &.alert {
    background: #D86561;
    color: #151419;
  }
}
