.card {
  background-color: #1f1e25;
  padding: 1.5rem;
  font-size: 14px;
  line-height: 140%;
  border-radius: 1rem;
  border: 0.75px solid rgba(255, 255, 255, 0.08);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;

  &[data-variant="secondary"] {
    background-color: #232031;
  }

  .cardTitle {
    color: #adaab5;
    margin-bottom: 1.25rem;

    svg {
      margin-left: 8px;
    }

    .cornerInfo {
      font-size: 1rem;
      color: #e0dfe3;
      float: right;
    }
  }

  &+.card {
    margin-top: 1.25rem;
  }
}

.cardContent {
  position: relative;
  flex-grow: 1;

  big {
    font-size: 2rem;
    font-weight: 500;
  }

  p {
    color: #e0dfe3;
    margin-bottom: 20px;
  }

  ul {
    li {
      margin-bottom: 20px;
      color: #e0dfe3;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}