.checkBoxCard {
  border: 1px #302e3a solid;
  border-radius: 12px;
}

.checkBoxLabel {
  display: flex;
  flex: 1;
  height: 100%;
  cursor: pointer;
}

.mainWrapper {
  width: 100%;
  min-height: 64px;
  line-height: 26px;
  height: 100%;

  .contentImg {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    max-width: 100%;
    object-fit: cover;
    height: 120px;
    width: 100%;
  }

  .CardWrapper {
    display: flex;
    justify-content: space-between;
    line-height: 26px;

    &.isLeft {
      flex-direction: row-reverse;
      justify-content: start;
    }
  }

  .contentWrapper {
    display: inline-flex;
    flex-direction: column;

    .contentIcon {
      margin-bottom: 12px;
    }

    .contentText {
      font-size: 16px;
    }
  }

  .checkBoxWrapper {
    line-height: 26px;
  }
}

.checkBoxTooltip {
  :global(.MuiTooltip-tooltip) {
    max-width: 550px;
  }
}