.rating {
  display: flex;
  align-items: center;
  background-color: #343128;
  border-radius: 8px;
  padding: 0 4px;
  max-width: 58px;

  svg.ratingIcon {
    margin-left: 0;
    font-size: 14px;
    height: 28px;
    width: 28px;
  }

  .ratingText {
    color: #F3F2F7;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.60px;
  }
}