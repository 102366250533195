.introVideoModal {
  h2.MuiDialogTitle-root {
    position: absolute;
    right: 0;
    top: 0;
  }
  .MuiDialogContent-root {
    padding: 0;
  }
}
