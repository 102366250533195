.mainWrapper {
  display: flex;
  background: #2c2b33;
  border-radius: 8px;
  font-size: 16px;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
  padding: 16px 8px 16px 16px;
  margin-bottom: 12px;
  max-height: 64px;

  .contentBox {
    display: flex;
    align-items: center;
    p {
      color: inherit;
    }
    .icon {
      margin-right: 16px;
    }
    .imageIcon {
      width: 24px;
      margin-right: 0;
      height: 24px;
      display: inline-block;
    }
    .threeDotsBtn {
      margin: 0 2px;
      padding: 0 10px 0 14px;
      height: auto;
    }
    .addSummaryBox {
      justify-content: start;
      .dropDown {
        width: 100%;
        max-width: 200px;
      }
      .imgSummary {
        order: 2;
      }
    }
  }
}
