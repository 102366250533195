.stepBoxWrapper {
  h4 {
    margin-bottom: 8px;
  }
}

.list {
  display: grid;
  grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
  gap: 12px;
}
