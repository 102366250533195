.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #33313bcf;
}

// Removing the outer background
.fc-theme-standard .fc-scrollgrid {
  border-top: 1px solid #33313bcf;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-radius: 1rem;
  overflow: hidden;
}
.fc-col-header-cell.fc-day {
  border-bottom: none;
  padding: 0.5rem 0 0.5rem;
}
// Changing the textColor of header
.fc .fc-col-header-cell-cushion {
  color: #adaab5;
  font-size: 14px;
  font-weight: 500;
}
.fc .fc-daygrid-event {
  margin: 0 !important;
}
// Styling the date
.fc .fc-daygrid-day-top {
  justify-content: center;
  margin-top: 10px;
  a {
    color: #e0dfe3;
    font-size: 14px;
    font-weight: 400;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    &:hover {
      border-radius: 32px;
      border: 0.75px solid #33313bcf;
      background-color: #33313bcf;
    }
  }
}

// Styling today's date
.fc .fc-daygrid-day.fc-day-today {
  background-color: #1f1e25;
  .fc-daygrid-day-top {
    a {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #c068cf;
      border-radius: 32px;
      font-size: 14px;
      font-weight: 500;
      color: #fdfcff;
    }
  }
}

// Mui Rating
.MuiRating-root {
  font-size: 48px !important;
}

@media screen and (max-width: 768px) {
  .fc .fc-view-harness {
    min-height: 500px;
  }
}
