.formWrapper {
  h4 {
    margin-bottom: 20px;
    position: relative;
  }

  p {
    font-size: 16px;
    color: #F8F7FA;
    text-align: end;
    margin: 0;

    span {
      color: #9A97A8;
    }
  }

  .error {
    font-size: 14px;
    padding-top: 4px;
    padding-left: 14px;
    text-align: left;
  }

  .list {
    display: grid;
    grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr);
    grid-auto-flow: dense;
    gap: 12px;
  }

  .inputRow {
    display: flex;
    flex-direction: column;

    .inputBg {
      width: 100%;
      background-color: #2c2b33 !important;
      margin-top: auto;
    }
  }
}

.textYellow{
  color: rgb(194 158 48);
}

.code {
  background-color: rgb(212 211 212 / 18%);
  color: rgb(225 225 225);
  word-spacing: -0.2em;
  padding: 0.05em 0.25em;
  line-height: 1;
  border-radius: 0.3em;
  vertical-align: middle;
}

.formulaInfo {
  display: inline-block;
  position: relative;
  margin-left: 8px;
  .icon {
    font-size: 18px;
  }
  .code{
    display: block;
    position: absolute;
    font-size: 0.8rem;
    white-space: nowrap;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px 8px;
    background-color: #3f3f44;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3 ease;
  }
  &:hover {
    .code {
      visibility: visible;
      opacity: 1;
      z-index: 1;
    }
  }
}