//colors
$primary-color: #E0DFE3;
$heading-color: #FDFCFF;
$green: #2EA082;
$voilet: #C068CF;
$light-voilet: #8977D8;
$red: #D86561;
$orange: #F08548;
$yellow: #C8B96F;

$primary-gradient: linear-gradient(273deg, #C068CF 0%, #7E7AD9 100%);
$rating-color: #F5CE40;

$input-color: #FDFCFF;
$placeholder-color: #9A97A8;
$label-color: #ADAAB5;
$radio: #2c2b33;

$grey-bg: #1F1E25;
$dark-bg: #151419;

// font
$primary-font: 'Inter', sans-serif;
$font-medium: 500;
$primary-size: 1rem;
$line-height: 1.4;  