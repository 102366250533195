.stepperWrapper, .stepInfoBox {
  max-width: 800px;
  margin: 0 auto;
}


.congratulationsWrapper {
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  .subtext {
    strong {
      color: #F3F2F7;
    }
  }
  .contentWrapper{
    margin: 48px 0;
    .contentText{
      display: flex;
      justify-content: space-between;
      align-items: start;
      border-bottom: 1px solid #302E3A;
      margin-top: 20px;


      h5{
        margin-bottom: 8px;
        text-align: start;
      }
      .heading{
        margin-bottom: 24px;
        & + p{
          margin-bottom: 0;
        }
      }

      p{
        font-size: 16px;
        color: #F3F2F7;
        margin-bottom: 20px;
        text-align: start;
      }
      .subHeading{
      margin-bottom: 6px;
      font-weight: 400;
      color: #E0DFE3;

     
      }
    }
   
  }
}
