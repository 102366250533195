.appLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;

  &.absoluteLoader{
    position: absolute;
  }

  .bouncingLoader {
    display: flex;
    justify-content: center;
  }

  .bouncingLoader > div {
    width: 1.5rem;
    height: 1.5rem;
    margin: 3rem 0.2rem;
    background: #8385aa;
    border-radius: 50%;
    animation: bouncingLoader 0.6s infinite alternate;
  }

  .bouncingLoader > div:nth-child(2) {
    animation-delay: 0.2s;
  }

  .bouncingLoader > div:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes bouncingLoader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -1rem, 0);
  }
}
