.congratulationsWrapper {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  h1{
    margin: 12px 0;
  }

  .subtext {
    margin-bottom: 32px;
    strong {
      color: #F3F2F7;
    }
  }
}
