.scheduledCallsFullViewContainer {
  .scheduledCallsFullViewHeader {
    display: flex;
    padding: 14px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-wrap: wrap;

    > span {
      color: #fdfcff;
      font-size: 20px;
      font-weight: 500;
      line-height: 135%;

      svg {
        color: #e0dfe3;
        cursor: pointer;
        margin-right: 0.7rem;
      }
    }
    div {
      display: flex;
      align-items: center;
      .scheduledCallsFullViewToday {
        border: 1.5px solid #302e3a;
        min-height: 44px;
        width: 95px;
        border-radius: 48px;
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fdfcff;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }
      svg {
        margin: 4px;
        cursor: pointer;
        margin: 0 0.7rem;
      }
      p {
        color: #f3f2f7;
        font-size: 16px;
        font-weight: 500;
        padding-left: 8px;
      }
      Button {
        margin-left: 24px;
      }
    }
  }

  .scheduledCallsFullViewBody {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
    height: 100%;

    > div {
      min-height: 230px;
      display: flex;
      flex-direction: column;
      padding: 12px 24px 8px 24px;
      align-items: center;
      flex: 1 0 0;
      align-self: stretch;
      border-top: 0.5px solid #33313bcf;
      border-right: 0.5px solid #33313bcf;
      border-bottom: 0.5px solid #33313bcf;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.firstDate {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background: #c068cf;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 500;
  color: #fdfcff;
}

.customEvent {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1.25rem;
  align-items: center;
  width: 100%;
  &.unpaidEvent {
    background-color: #ff5349;
    cursor: pointer;
  }
  &.cancelEvent {
    background-color: #b52f2f;
    cursor: pointer;
  }
  > div {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #f3f2f7;
    cursor: pointer;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    &:first-child {
      color: #adaab5;
      padding-right: 0.5rem;
    }
    &:nth-child(2) {
      color: #f3f2f7;
    }
  }
  img {
    width: 24px;
    height: 24px;
    border-radius: 40px;
    margin-left: 0.5rem;
  }
}

.callDetails {
  li {
    display: flex;
    width: 100%;
    margin: 0.75rem 0;
    align-items: center;
    overflow: hidden;
    float: left;
    .customerName {
      background-color: #2c2b33;
      border-radius: 100px;
      color: #f8f7fa;
      padding-right: 0.75rem;
      font-size: 14px;
      img {
        width: 32px;
        height: 32px;
        border-radius: 100px;
        margin-right: 0.5rem;
      }
    }
    p {
      color: #fdfcff;
    }
    > svg {
      margin-right: 1.5rem;
      path {
        fill: #9a97a8;
      }
    }
  }
  .files > svg {
    align-self: flex-start;
    margin-top: 4px;
  }
  .fileList {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    a {
      color: #e0dfe3;
      display: flex;
      flex-direction: column;
      font-size: 0.75rem;
      text-align: center;

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 5rem;
        height: 1rem;
      }
      svg {
        width: 5rem;
        height: 5rem;
        padding: 1.25rem;
        border: 1px solid rgba(255, 255, 255, 0.08);
        border-radius: 0.75rem;
        margin-bottom: 0.25rem;

        path {
          fill: #fdfcff;
          opacity: 0.3;
        }
      }
    }
  }
}

.rateEngagementBodyContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  p {
    font-size: 14px;
    color: #e0dfe3;
    font-weight: 500;
  }
  div {
    > small {
      font-size: 14px;
      color: #adaab5;
      font-weight: 400;
    }
    div {
      display: flex;
      gap: 0.5rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .scheduledCallsFullViewHeader {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    div {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      align-items: center;
    }
  }
}

.zoomLinkWrapper{
border-radius: 10px;
 padding: 11px 12px;
 margin-top: 13px;
}
.zoomLink{
  color: #adaab5;
  background: #2c2b33fc;
}    
.popoverError{
  color: #b52f2f;
  background: #b52f2f26;
}
