.steps{
  display: flex;
  gap: 6px;
  margin-bottom: 1.25rem;

  .step {
    height: 4px;
    flex: 1;
    background-color: #E0DFE3;
    border-radius: 1rem;

    &.activeStep~.step {
      background-color: #302E3A;
    }
  }
}

.stepperTitle {
  font-size: 2rem;
  margin-bottom: 1.25rem;
  margin-top: 0;
}

.stepperFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.25rem;
}