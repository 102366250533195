.upcomingCallsDrawer {
  width: 360px;
  height: calc(100vh - 100px) !important;
  margin: 5rem 1.7rem 2rem 0;
  border-radius: 1rem;
  background-color: #302e3a !important;
}

.upcomingCallsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  .upcomingCallsHeader {
    display: flex;
    height: 72px;
    padding: 0px var(--size-spacing-12, 12px) 0px var(--size-spacing-24, 24px);
    align-items: center;
    border-bottom: 1px solid #444250;
    justify-content: space-between;
    margin-bottom: 0;
    flex-shrink: 0;

    div {
      color: #fdfcff;
    }

    .upcomingCallsCloseIcon {
      display: flex;
      width: 44px;
      height: 44px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 64px;
      background: rgba(223, 214, 255, 0.1);
      cursor: pointer;
      color: #e0dfe3;
    }
  }

  .upcomingCallsBody {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: auto;
    .upcomingCallsBodyData {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12px;
      border-top: 0.5px solid #444250;
      border-bottom: 0.5px solid #444250;
      .currentDate {
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        background: linear-gradient(273deg, #c068cf 0%, #7e7ad9 100%),
          var(--colors-neutral-100, #f3f2f7);
        border-radius: 32px;
        font-size: 14px;
        font-weight: 500;
        color: #fdfcff;
      }
      .date {
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        &:hover {
          border-radius: 32px;
          border: 0.75px solid #33313bcf;
          background-color: #33313bcf;
        }
      }
      .upcomingCallsTimeSubjectImage {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        span {
          padding-right: 8px;
          font-size: 14px;
          font-weight: 400;
          color: #adaab5;
        }
      }
      img {
        width: 24px;
        height: 24px;
        border-radius: 40px;
      }
    }
  }

  .upcomingCallsAction {
    position: sticky;
    width: 100%;
    height: 52px;
    bottom: 0;
    padding: 12px;
    text-align: center;
    border-radius: 16px;
    background: rgba(223, 214, 255, 0.1);
    font-size: 16px;
    font-weight: 500;
    color: #e0dfe3;
    cursor: pointer;
    flex-shrink: 0;
  }
}
