
.loaderWrapper {
  position: relative;
  margin-top: calc(25px + 25vh);
}

//sub-header
.subHeader {
  display: flex;
  padding: 24px;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  padding: 1rem 0;

  .headerLeft {
    display: flex;
    align-items: center;
    gap: 1.7rem;

    .menuItems {
      & > div {
        margin-right: 16px;

        .menuOption {
          font-size: 16px;
          padding: 20px 16px;

          svg {
            cursor: pointer;

            path {
              fill: currentColor;
            }
          }
        }
      }

      .SortingSelect {
        border-radius: 0;
        padding: 0px 0px 5px 0px;

        &::after {
          content: "";
          position: absolute;
          border-bottom: 1px solid var(--Colors-Neutral-600, #5a576a);
          width: calc(100% - 32px);
          bottom: 0;
        }

        & > div {
          padding-right: 32px;
        }

        svg {
          color: #adaab5;
          cursor: pointer;
          margin-left: 0;
          right: 0px;

          path {
            fill: currentColor;
          }
        }
      }
    }
  }
}

.menuItems {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;

  .inputBg {
    font-size: 20px;
  }
}

.headerRight {
  .menuItems {
    & > div {
      margin-right: 12px;

      .avatarGroupIcon {
        div {
          height: 32px;
          width: 32px;
          border: 2px solid #151419;
          background: #302e3a;
        }

        .addMoreIcon {
          font-size: 16px;
        }
      }

      .userIcon {
        height: 32px;
        width: 32px;
        margin-bottom: 11px;
      }

      .addProfile {
        height: 32px;
        width: 32px;
        border-radius: 40px;
        border: 2px solid #151419;
        background: #302e3a;
        padding: 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

//programme-view

.cardContent {
  &.detailCard {
    margin-top: -1.2rem;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));;
    gap: 12px;
    overflow: hidden;
    max-height: 173px;
    &.listContainer {
      height: auto;
      overflow-y:auto;
    }

    .providerImg {
      object-fit: cover;
      height: fit-content;
    }
  }

  .addBorder {
    border-radius: 8px;
    border: 2px dashed rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    margin-bottom: 12px;
    padding: 8px;

    .addIcon {
      padding-right: 8px;
      color: inherit;
    }
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;

  .SortingSelect {
    padding: 0;
  }
}

.KpiWrapper {
  margin-top: 20px;
  overflow-x: auto;
  padding: 0;
  width: 100%;

  .headers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px 8px 24px;
  }

  .tableContainer {
    background: no-repeat;
  }

  .tableWrapper {
    background-color: #1f1e25;
    border-radius: 0;
    box-shadow: none;

    thead {
      tr {
        th {
          border-bottom: 0.75px solid hsla(0, 0%, 100%, 0.12);
          color: #adaab5;
          font-size: 14px;
          padding: 21px 24px;
        }
      }
    }

    tbody {
      tr {
        td {
          border-bottom: 0.5px solid rgba(253, 252, 255, 0.08);
          color: #e0dfe3;
          font-size: 1rem;
          font-weight: 500;
          padding: 0 24px;
        }

        .keyAssignee {
          min-width: 32px;
          width: 32px;
          height: 32px;
          background-color: rgba(92, 92, 92, 0.2);
        }
      }
    }
  }
}

.projectStages {
  ul {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);

    &:nth-child(3) {
      border: none;
      padding-bottom: 0;
    }

    li {
      margin: 0;
    }
  }
}

.addIcon {
  margin-bottom: 5px;
}

.plusIcon {
  margin-right: 8px;
}

// modal css
.inputSection {
  .checkBoxWrapper {
    padding: 10px 0;
  }
}


.darkAccordion {
  background-color: transparent;
  box-shadow: none;

  .expandMore {
    color: #adaab5;
  }
}

.collaboratorsList {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 16px 0;

  .collaboratorsName {
    flex: 1;
  }
}

.collaboratorDropdown {
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 10px;

  .leftItem {
    min-width: 300px;
   
  }

  .rightItem {
    margin-top: -5px;
  }
}
.autoCompleteInput{
  .autoInput{
    div{
      padding: 3px 9px;
    }
    
  }
  .autoInputCollaborators{
    div{
      padding: 4px 9px;
    }
  }
  label{
    color: #adaab5;
  }
}

.buttonWrapper {
  display: flex;
  gap: 20px;
}

.docUsericon {
  border-radius: 50%;
}

.docListWrap {
  overflow: auto;
  max-height: 439px;
  height: calc(100% - 65px);

  .listItemDesign {
    color: #F8F7FA;
  }
}

.failedStatus {
  margin: 0 auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.ListItemCustomBox {
  max-height: 228px; 
  overflow: auto;
  display: grid;
  grid-template-columns: 50% 50%;
  margin-inline: -6px;
  
  &>div {
    padding-inline: 6px;
    cursor: pointer;
  }
}