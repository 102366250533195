@import "variables";
@import "mixins";
@import "bs-mui-override";

// reset css
@media print {
  html,
  body {
    height: 100vh;
    /* Use 100% here to support printing more than a single page*/
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    color: #151218;
  }
}

body {
  margin: 0;
  padding: 0;
  font-size: $primary-size;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #151218;
  box-sizing: border-box;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: $heading-color;
}

p {
  margin: 0;
  color: $primary-color;
  word-break: break-word;
}

strong,
b {
  font-weight: 500;
}

a {
  text-decoration: none;
}

a.link {
  color: #fdfcff;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

#root {
  height: 100%;
  width: 100%;

  .App {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }
}

h2,
.h2 {
  font-size: 24px;
  font-weight: 500;
  margin: 1rem 0 1rem 0;
  color: #fdfcff;
  margin-bottom: 32px;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* custom checkbox */
.custom-checkradio {
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #302e3a;
    border-radius: 6px;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 3px;
      width: 7px;
      height: 14px;
      border: solid #fdfcff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  [type="radio"] + .checkmark {
    border-radius: 20px;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 7px;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: #fdfcff;
    }
  }

  &:hover input ~ .checkmark {
    background-color: #49465a;
  }

  input:checked ~ .checkmark {
    background: $primary-gradient;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
}

/*radio wrapper*/
.radio-wrapper {
  border: 1px solid #302e3a;
  padding: 16px;
  color: #e0dfe3;
  border-radius: 12px;
  margin-bottom: 12px;
}

.info_icon {
  font-size: 1rem !important;
}

.input-row {
  margin-bottom: 20px;

  label {
    font-size: 14px;
    color: $label-color;
    margin-bottom: 6px;
  }

  .input-icon {
    position: relative;

    .input-field {
      padding-left: 2.75rem;
    }

    svg {
      position: absolute;
      top: 0.8rem;
      left: 1rem;
    }

    span {
      position: absolute;
      top: 2.2rem;
      right: 1rem;
      color: #f3f2f7;
      font-size: 16px;
    }
  }

  .input-wrap {
    position: relative;
    .input-field {
      padding-right: 100px;
    }
    .input-icon {
      color: #f3f2f7;
      display: block;
      position: absolute;
      max-width: 5em;
      padding-block: 0.25em;
      right: 10px;
      top: calc(50% - 0.75em);
      line-height: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

// form fields
.input-field {
  width: 100%;
  border: 1px solid transparent;
  padding: 10px 16px;
  align-items: center;
  background: #1f1e25;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #fdfcff;
  border-radius: 50rem;

  &:focus {
    background: #1f1e25;
    outline: none;
    box-shadow: none;
    border: 1px solid #fdfcff;
    color: #fdfcff;
  }
}

.textarea-field {
  resize: none;
  width: 100%;
  border: 1px solid transparent;
  padding: 10px 16px;
  align-items: center;
  background-color: #2c2b33;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #fdfcff;
  border-radius: 1.3rem;

  &:focus {
    background: #1f1e25;
    outline: none;
    box-shadow: none;
    outline: 1px solid #fdfcff;
    color: #fdfcff;
  }
}

.select {
  background-color: #1f1e25;
  color: #9a97a8;
  width: 100%;
  padding: 10.5px 16px;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border-right: 16px solid transparent;
  border-radius: 50rem;
}

body .custom-badge {
  background-color: $grey-bg;
  font-family: inherit;
  font-weight: 400;
}

.mainWrapper {
  min-height: 100vh;
  border: 1px solid black;
  background: #302e3a;
  padding: 0 12px 12px;
}

.contentWrapper {
  background-color: #151419;
  min-height: calc(100vh - 86px);
  padding: 32px;
  border-radius: 24px;
  color: #fff;
  position: relative;
  z-index: 2;
}

.bulletList li {
  list-style: disc;
  list-style-position: inside;
  text-indent: -20px;
  margin-left: 20px;
}

.checkList li {
  padding-left: 30px;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    transform: rotate(45deg);
    height: 15px;
    width: 8px;
    border-bottom: 2px solid #e0dfe3;
    border-right: 2px solid #e0dfe3;
    left: 1px;
  }
}

.text-justify {
  text-align: justify;
}

.noData {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  svg {
    width: 50px;
    height: 50px;

    path {
      fill: #5a576a;
    }
  }

  p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #adaab5 !important;
    margin: 0.75rem 0 0 !important;
  }
}

.gridCol {
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 11px;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: #909090;
  border-width: 0px 2px 0px 7px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(273deg, #c068cf 0%, #7e7ad9 100%);
  border-width: 0px 0px 0px 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background: #355cc9;
  background-clip: padding-box;
}

.pointer {
  cursor: pointer;
}

.dot-pulse {
  position: relative;
  display: block;
  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
  margin: 5px 20px;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }

  30% {
    box-shadow: 9984px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }

  30% {
    box-shadow: 9999px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }

  30% {
    box-shadow: 10014px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

/* dot pulse ends*/

.font16 {
  font-size: 16px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

#datepickerPopper {
  z-index: 9;
}

#react-day-picker {
  background-color: #2c2b33;
  padding: 1rem;
  margin: 0;
  border-radius: 1rem;
  color: #fdfcff;

  .rdp-head_cell,
  .rdp-caption_label,
  .rdp-day_today:not(.rdp-day_outside) {
    font-weight: 500;
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background: linear-gradient(273deg, #c068cf 0%, #7e7ad9 100%);
    border: none;
  }

  .rdp-day_selected,
  .rdp-day_selected:focus-visible,
  .rdp-day_selected:hover {
    background-color: #2ea082;
  }
}

.txt-success {
  color: #2ea082 !important;
}

.txt-danger {
  color: #d86561 !important;
}

.iconCircle {
  background-color: #2c2b33;
  border-radius: 2.5rem;
  margin-right: 0.75rem;
  display: inline-block;

  svg,
  img {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.75rem;

    &.payoutIcon {
      transform: rotate(45deg);
    }

    path {
      fill: #e0dfe3;
    }
  }

  img {
    filter: brightness(0) invert(1);
  }
}

// profile page loader
@-webkit-keyframes lightMe1 {
  0% {
    opacity: 0.1;
  }

  25% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes lightMe1 {
  0% {
    opacity: 0.1;
  }

  25% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes lightMe2 {
  0% {
    opacity: 0.1;
  }

  25% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes lightMe2 {
  0% {
    opacity: 0.1;
  }

  25% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes lightMe3 {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes lightMe3 {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes lightMe4 {
  0% {
    opacity: 0.1;
  }

  75% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes lightMe4 {
  0% {
    opacity: 0.1;
  }

  75% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slider {
  0% {
    transform: translateY(0);
  }

  10.5% {
    transform: translateY(0);
  }

  12.5% {
    transform: translateY(-40px);
  }

  23% {
    transform: translateY(-40px);
  }

  25% {
    transform: translateY(-80px);
  }

  35% {
    transform: translateY(-80px);
  }

  37.5% {
    transform: translateY(-120px);
  }

  47.5% {
    transform: translateY(-120px);
  }

  50% {
    transform: translateY(-160px);
  }

  60% {
    transform: translateY(-160px);
  }

  62.5% {
    transform: translateY(-200px);
  }

  72.5% {
    transform: translateY(-200px);
  }

  75% {
    transform: translateY(-240px);
  }

  85% {
    transform: translateY(-240px);
  }

  87.5% {
    transform: translateY(-280px);
  }

  100% {
    transform: translateY(-280px);
  }
}

@keyframes slider {
  0% {
    transform: translateY(0);
  }

  10.5% {
    transform: translateY(0);
  }

  12.5% {
    transform: translateY(-40px);
  }

  23% {
    transform: translateY(-40px);
  }

  25% {
    transform: translateY(-80px);
  }

  35% {
    transform: translateY(-80px);
  }

  37.5% {
    transform: translateY(-120px);
  }

  47.5% {
    transform: translateY(-120px);
  }

  50% {
    transform: translateY(-160px);
  }

  60% {
    transform: translateY(-160px);
  }

  62.5% {
    transform: translateY(-200px);
  }

  72.5% {
    transform: translateY(-200px);
  }

  75% {
    transform: translateY(-240px);
  }

  85% {
    transform: translateY(-240px);
  }

  87.5% {
    transform: translateY(-280px);
  }

  100% {
    transform: translateY(-280px);
  }
}

@-webkit-keyframes blink {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 0.75;
  }
}

@keyframes blink {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 0.75;
  }
}

.wrap-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 67vh;
  background: #1f1e25;
  border-radius: 1rem;
}

.loader {
  position: relative;
  width: 20rem;
  height: 20rem;

  &::before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8.6956521739rem;
    height: 8.6956521739rem;
    background: #000;
    border-radius: 50%;
  }

  .box {
    position: absolute;
    width: 10rem;
    height: 10rem;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      opacity: 0.1;
    }

    &:nth-child(1) {
      top: 0;
      left: 0;

      &::before {
        top: 50%;
        left: 50%;
        background: #4185f3;
        -webkit-animation: lightMe1 4s ease-out infinite normal;
        animation: lightMe1 4s ease-out infinite normal;
      }
    }

    &:nth-child(2) {
      top: 0;
      right: 0;

      &::before {
        top: 50%;
        right: 50%;
        background: #33a753;
        -webkit-animation: lightMe2 4s ease-out infinite normal;
        animation: lightMe2 4s ease-out infinite normal;
      }
    }

    &:nth-child(3) {
      bottom: 0;
      right: 0;

      &::before {
        bottom: 50%;
        right: 50%;
        background: #fabb04;
        -webkit-animation: lightMe3 4s ease-out infinite normal;
        animation: lightMe3 4s ease-out infinite normal;
      }
    }

    &:nth-child(4) {
      bottom: 0;
      left: 0;

      &::before {
        bottom: 50%;
        left: 50%;
        background: #e94234;
        -webkit-animation: lightMe4 4s linear infinite normal;
        animation: lightMe4 4s linear infinite normal;
      }
    }
  }

  .wrap-text {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    overflow: hidden;
  }

  .text {
    position: relative;
    width: 40px;
    height: 40px;
    line-height: 40px;
    -webkit-animation: slider 8s ease-in infinite;
    animation: slider 8s ease-in infinite;

    span {
      display: block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 2em;
      color: #fff;
    }
  }
}

.loader-text {
  color: #fff;
  transform: translateY(-2rem);
  letter-spacing: 6px;
  opacity: 0.2;
  -webkit-animation: blink 1s ease-out infinite alternate;
  animation: blink 1s ease-out infinite alternate;
}

/*profile loader ends*/

@media print {
  .no-print {
    display: none;
  }
}

.fc .fc-cell-shaded,
.fc .fc-day-disabled {
  background-color: transparent;
}

/*spline wave chart - highcharts*/
.waveChart {
  padding: 1.5rem;
  border-radius: 1rem;
  border: 0.75px solid rgba(255, 255, 255, 0.08);
  background: #232031;
  height: 100%;

  .chartHeader {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;

    h3 {
      flex-grow: 1;
      font-size: 1.25rem;
    }

    .chartLegends {
      display: flex;
      gap: 2rem;
      align-items: center;
      margin-right: 4rem;

      li {
        font-size: 14px;

        &:before {
          content: "";
          height: 0.5rem;
          width: 0.5rem;
          background-color: currentColor;
          border-radius: 0.5rem;
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.5rem;
        }

        &:first-child {
          color: #c068cf;
        }

        &:last-child {
          color: #2ea082;
        }
      }
    }

    .chartDataFilter + .chartDataFilter {
      margin-left: 1.5rem;
    }
  }
}

.noDataBigText {
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 500;
  display: block;
  margin: auto;
  text-align: center;
  padding: 2.5rem 0 2.5rem;
  color: rgb(255 255 255 / 30%);

  small {
    font-size: 0.75em;
  }
  svg {
    font-size: 2em;
    width: 1.5em;
    height: auto;
    margin-bottom: 0.25em;
  }
}

// Hide Input type number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.text-ellipsis {
  @include text-shorten;
}

.MuiAutocomplete-popper {
  .MuiAutocomplete-paper {
    background-color: #2c2b33;
    color: rgb(224, 223, 227);
    border-radius: 1rem;
    .MuiAutocomplete-option.Mui-focused {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.text-pre {
  font-family: inherit;
  color: #e0dfe3;
  font-size: 0.9rem;
  white-space: pre-line;
  line-height: 1.5;
}
