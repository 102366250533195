@import "../../../styles/mixins";
.header {
  width: 100%;
  background: #302e3a;
  display: flex;
  padding: 8px 32px 0;
  justify-content: space-between;
  color: #adaab5;

  .headerLeft {
    display: flex;
    align-items: center;
    gap: 1.7rem;
  }

  .headerMobile {
    display: none;
  }

  .menuItems {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    li a {
      color: inherit;
      text-decoration: none;
      padding: 20px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      cursor: pointer;
      position: relative;
      svg {
        margin-right: 12px;
      }
      &:hover,
      &.active,
      &[aria-current="page"] {
        color: #e0dfe3;
        background-color: #151419;
        border-radius: 30px 30px 0 0px;
        svg path {
          fill: #e0dfe3;
        }
        &::before {
          content: "";
          position: absolute;
          top: 3px;
          left: -34px;
          height: 61px;
          width: 34px;
          border-bottom-right-radius: 25px;
          -moz-border-bottom-right-radius: 25px;
          -webkit-border-bottom-right-radius: 25px;
          box-shadow: 0 40px 0 0 #151419;
          z-index: 1;
        }
        &::after {
          content: "";
          position: absolute;
          top: 3px;
          right: -34px;
          height: 61px;
          width: 34px;
          border-bottom-left-radius: 25px;
          -moz-border-top-right-radius: 25px;
          -webkit-border-bottom-left-radius: 25px;
          box-shadow: 0 40px 0 0 #151419;
        }
      }
    }
  }

  .headerRight {
    display: flex;
    align-items: center;
    list-style: none;
    li {
      margin: 0 15px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }

    .userIcon {
      height: 2.75rem;
      width: 2.75rem;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }
  @include tablet-portrait {
    padding: 15px 0 10px;
    .headerMobile {
      display: block;
    }
    .headerLeft {
      display: none;
    }
  }
}

.dynamicHeader {
  height: 100vh;
  padding: 1rem 0;
  background: #302e3a;
  color: #fff;
  text-align: left;
  .logo {
    padding: 0 1rem 1rem;
  }

  li {
    
    display: block;
    a {
      display: block;
      text-decoration: none;
      color: inherit;
      padding: 1rem;
      &[aria-current="page"] {
        color: #e0dfe3;
        background-color: #151419;
      }
    }
    svg {
      margin-right: 10px;
    }
  }

  img {
    width: 50px;
    height: 50px;
  }
}
