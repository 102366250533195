.addBorder {
  align-items: center;
  background-blend-mode: soft-light, normal;
  background-color: #2c2b33;
  border: 2px dashed rgba(255, 255, 255, 0.3);
  border-radius: 22px;
  color: #e0dfe3;
  display: flex;
  flex-direction: column;
  height: 120px;
  height: 110px;
  justify-content: center;
  margin-bottom: 0.5rem;
  padding: 24px;
  margin-top: 20px;
  width: 100%;
  min-height: 160px;
  position: relative;
  cursor: pointer;

  .clearIcon {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    cursor: pointer;
  }

  .iconWrapper {
    pointer-events: none;
  }

  .fileName {
    font-size: 1rem;
    margin-top: 0.5rem;
    text-align: center;
    pointer-events: none;
  }

  .infoText {
    text-align: center;
    margin-top: 0.5rem;
    pointer-events: none;

    strong {
      color: #E0DFE3;
      font-size: 20px;
      font-weight: 500;
      display: block;
      margin-bottom: 0.75rem;
    }

    .orText {
      color: #adaab5;
      margin-right: 12px;
    }

    span {
      color: #FDFCFF;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.instructionsText {
  font-size: 14px;
  font-weight: 400;
  color: #adaab5;
}